
import {defineComponent, ref, reactive, onMounted, watch, createVNode} from 'vue';
import { useStore } from 'vuex';
import { SearchConfig } from '@/components/page-model/typing';
import moment from 'moment';
import { useForm } from 'ant-design-vue/es/form';

import {
  analyse_production_total_result,
  get_product_info_by_test_opc_info,
  query_production_record_item_data_list,
  set_know_no_to_one
} from '@/api/production_manage-model.ts';
import {query_alarm_system_list, query_alarm_list} from "@/api/alarm-model";
import {message, Modal, notification} from "ant-design-vue";
import modalBox from "@/components/form-modal/modal-tools";
import {get_plan_excel_import_setting_by_flag, set_plan_excel_import_setting_by_flag} from "@/api/sys-model";
import {useTableDynamicColumns} from "@/utils/hooks/useTableColumn";
import {TableColumn} from "@/typing";
import {ExclamationCircleOutlined} from "@ant-design/icons-vue";
import {isNumber} from "lodash";
import ls from "@/utils/local-storage";
import {STORAGE_CURRENT_ORG_KEY} from "@/store/mutation-type";
export default defineComponent({
  setup() {
    const current_org = ls.get(STORAGE_CURRENT_ORG_KEY); // 当前组织org
    const tabList: any = ref([]);
    const tabActiveKey: any = ref(null);
    const search_config: SearchConfig = {
      settings: {
        labelAlign: 'right',
        layout: 'inline',
      },
      fields: [
        {
          type: 'radio',
          name: 'billet_type',
          label: '坯料种类',
          mode: 'default',
          disabled: false,
          defaultValue: '',
          labelKey: 'name',
          valueKey: 'value',
          datasource: 'billet_type',
        },
        {
          type: 'remoteselect',
          name: 'steel_type',
          label: '成品钢种',
          disabled: false,
          placeholder: '请选择成品钢种',
          datasourceType: 'remote',
          init_model: { is_madeup: true, aim_org_id: current_org.id },
          labelKey: 'name',
          valueKey: 'name',
          mode: 'default',
          allowClear: true,
          modalType: 'material_storage-query_steel_type_list',
        },
        {
          type: 'input',
          name: 'prod_thick',
          label: '成品口径',
          disabled: false,
          allowClear: true,
          inputType: 'text',
          defaultValue: '',
          placeholder: '请填写成品口径',
        },
        {
          type: 'input',
          name: 'prod_len',
          label: '定尺长度（mm）',
          disabled: false,
          allowClear: true,
          inputType: 'number',
          defaultValue: '',
          placeholder: '请填写成品长度',
        },
        {
          type: 'select',
          name: 'billet_thick',
          label: '钢坯截面',
          mode: 'default',
          disabled: false,
          defaultValue: '',
          labelKey: 'name',
          valueKey: 'value',
          placeholder: '请选择钢坯截面',
          datasource: 'billet_thick',
        },
      ],
      rules: reactive({}),
      model: reactive({
        org_id: current_org?.id,
        is_active: true,
        billet_type: null,
        steel_type: null,
        prod_thick: null,
        prod_len: null,
        billet_thick: null,
      }),
    };
    const search_options = {
      billet_type: [
        { value: 'C', name: '冷装' },
        { value: 'H', name: '热装' },
      ],
      billet_thick: [
        { name: '165', value: '165' },
        { name: '160', value: '160' },
        { name: '155', value: '155' },
        { name: '150', value: '150' },
      ],
    };
    const { resetFields, validateInfos } = useForm(search_config.model, search_config.rules);
    query_alarm_system_list({
      org_id: current_org?.id,
      is_active: true,
      pageSize: 2000,
    }).then((res: any) => {
      res.data.map((item: any) => {
        tabList.value.push(item);
      })
      tabActiveKey.value = tabList.value[0].id;
      search();
    })
    const search = () => {
      query_alarm_list({
        ...search_config.model,
        alarm_system_id: tabActiveKey.value,
      }).then((res: any) => {
        res.data.map((item: any) => {
          if(item.first_max && item.first_min){
            item.first_num = (item.first_max + item.first_min)*1000 / 2000;
            item.first_num_cha = item.first_max - ((item.first_max + item.first_min)*1000 / 2000);
          }else if(!item.first_max && item.first_min){
            item.first_num_min = item.first_min;
          }else if(item.first_max && !item.first_min){
            item.first_num_max = item.first_max;
          }
        })
        tabList.value.map((item: any) => {
          if(item.id == tabActiveKey.value){
            item.data = res.data;
          }else{
            item.data = [];
          }
        })
      })
    };

    const handleTabChange = (activeKey: any) => {
      tabActiveKey.value = Number(activeKey);
      search();
    }
    const getDateTime = (time: any) => {
      if (time && time.substring(0, 4) < 2000) {
        return '';
      }
      if (time && new Date().getFullYear().toString() == time.substring(0, 4)) {
        time = time.substring(5, 16);
      } else if (time) {
        time = time.substring(0, 16);
      }
      return time;
    };
    const toFixed2 = (num: any) => {
      if (isNumber(num)) {
        if (Number(num.toFixed(2)) == 0) {
          return 0;
        } else {
          return num.toFixed(2);
        }
      } else {
        return '';
      }
    };
    return {
      search_config,
      search_options,
      resetFields,
      validateInfos,
      tabList,
      tabActiveKey,
      search,
      getDateTime,
      handleTabChange,
      toFixed2,
    };
  },
});
